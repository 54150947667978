.header-container {
  border-bottom: 1px solid #e0e0e0;
}

.add-icon {
  margin-top: 6px;
  font-size: 28px;
  cursor: pointer;
  color: #f44336;
}

.ant-page-header-heading-extra {
  margin: 0px !important;
}

.field {
  margin-bottom: 10px !important;
}

.success {
  background-color: #c2f3cb !important;
}

.error {
  background-color: #eec7d6 !important;
}

.error-message {
  color: red;
  text-align: left;
}

.account-icon {
  font-size: 30px;
  font-size: 28px;
  color: gray;
}

.account-circle{
  cursor: pointer;
}